import React from 'react';
import { Chip } from '@mui/material';
import { ReactComponent as ChipCheckedIcon } from '../../images/icons/chip-checked-icon.svg';
import { ReactComponent as ChipUncheckedIcon } from '../../images/icons/chip-unchecked-icon.svg';

type CheckboxChipProps = {
  label: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
};

const CheckboxChip = ({ label, checked, onChange }: CheckboxChipProps) => {
  return (
    <Chip
      icon={checked ? <ChipCheckedIcon /> : <ChipUncheckedIcon />}
      label={label}
      onClick={() => {
        onChange?.(!checked);
      }}
      variant="outlined"
      style={{
        boxShadow: `inset 0px 0px 0px ${checked ? '2' : '1'}px rgb(0 0 0 / 1)`,
        fontWeight: checked ? 'bold' : 'normal',
      }}
    />
  );
};

export default CheckboxChip;
