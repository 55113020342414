import React from 'react';
import PolicyLookup from '../../PolicyLookup';
import ConfirmationPage from '../../ConfirmationPage';
import PolicyDetails from '../../PolicyDetails';
import DocumentDetails from '../../DocumentDetails';
import ReviewRequest from '../../ReviewRequest';
import Root from '../../Root';
import MaintenanceCard from '../MaintenanceCard';
import OtvcCard from '../OtvcCard';

const paths = [
  { '*': <PolicyLookup /> },
  { '/': <PolicyLookup /> },
  { otvc: <OtvcCard /> },
  { maintenance: <MaintenanceCard /> },
  { details: <PolicyDetails /> },
  { documents: <DocumentDetails /> },
  { review: <ReviewRequest /> },
  { success: <ConfirmationPage /> },
];

export const getRoutes = () => {
  const children = paths.map((obj) => {
    const path = Object.keys(obj)[0];
    const element: any = Object.values(obj)[0];
    return {
      path,
      element:
        process.env.REACT_APP_MAINTENANCE === 'true' ? (
          <MaintenanceCard />
        ) : (
          element
        ),
    };
  });
  return [
    {
      path: '/',
      element: <Root />,
      children,
    },
  ];
};
