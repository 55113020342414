import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import { useEffect } from 'react';
import {
  Container,
  Dialog,
  DialogProps,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '../../images/icons/close-icon.svg';
import '../../styles/common/Modal.scss';

type ModalProps = {
  title: string | ReactElement;
  children: React.ReactNode;
  open: boolean;
  onClose: any;
  modalName: string;
  onGoBack?: () => void;
};

const Modal = ({
  title,
  children,
  open,
  onClose,
  modalName,
  ...rest
}: ModalProps & Omit<DialogProps, 'title'>) => {
  useEffect(() => {
    // NOTE: This allows to reset focus so that keyboard navigation
    // can continue from the top of the screen (instead of from the bottom like
    // it is currently)
    if (!open) {
      const container = document.getElementById('landing-page-container');
      container && container.focus();
    }
  }, [open]);

  const { className } = rest;
  return (
    <Dialog
      aria-labelledby={'csaa-modal-title'}
      aria-describedby={'csaa-modal-title'}
      {...rest}
      open={open}
      onClose={onClose}
      className={`modal ${className}`}
    >
      <Box className="modal-content">
        <Container className="modal-header">
          <Container className="icon-container">
            <IconButton
              className="modal-close-icon"
              disableTouchRipple
              aria-label="close"
              onClick={onClose}
            >
              <img alt="close" src={CloseIcon} style={{ fontSize: '16px' }} />
            </IconButton>
          </Container>
          <Typography variant="h5" id="csaa-modal-title">
            {title}
          </Typography>
        </Container>
        <div>{children}</div>
      </Box>
    </Dialog>
  );
};

export default Modal;
