import dayjs from 'dayjs';
import {
  Insured,
  Mortgagee as PoliciesMortgagee,
  Mortgagee as PolicyMortgagee,
  PolicyResponseDetails,
} from '../api/schema/apiPolicies';
import {
  DocumentsRequest,
  Mortgagee as DocMortgagee,
} from '../api/schema/apiDocuments';
import { InsuredContextState } from '../components/contexts/InsuredContext';

const getLienNumberText = (nr: number): string => {
  switch (nr) {
    case 1:
      return nr + 'st Lien';
    case 2:
      return nr + 'nd Lien';
    case 3:
      return nr + 'rd Lien';
    default:
      return nr + 'th Lien';
  }
};

const mapToLien = (mortgagee: PoliciesMortgagee | undefined): DocMortgagee => {
  if (!mortgagee) return {} as DocMortgagee;
  return {
    lienPosition: mortgagee.lienPosition,
    legalMortgageeName: mortgagee.bankName,
    mortgageeAddress1: mortgagee.address?.addressLine1,
    mortgageeCity: mortgagee.address?.city,
    mortgageeState: mortgagee.address.stateProvCd,
    mortgageePostalCode: mortgagee.address.postalCode,
    loanNumber: mortgagee.loanNumber,
  };
};

const addNewLien = (
  lienList: Array<DocMortgagee>,
  lien: DocMortgagee,
  lienPosition: number
) => {
  return lienList.concat({
    ...lien,
    ...{
      requestType: 'add',
      lienPosition,
    },
  });
};

const markExistingLienToBeEdited = (
  lienList: Array<DocMortgagee>,
  lienPosition: number,
  lien: DocMortgagee
) => {
  return lienList.map((l) =>
    lienPosition === l.lienPosition
      ? { ...l, ...lien, ...{ requestType: 'edit', lienPosition } }
      : l
  );
};

const markExistingLienToBeDeleted = (
  mortgagee: Array<PolicyMortgagee>,
  lienList: Array<DocMortgagee>,
  lienPosition: number
) => {
  const existingLien = mortgagee?.find((m) => m.lienPosition === lienPosition);
  return lienList.map((lien) => {
    return lien.lienPosition === lienPosition
      ? {
          ...lien,
          ...mapToLien(existingLien),
          ...{ requestType: 'delete' },
        }
      : lien;
  });
};

const replaceExistingLienWithNewlyAddedOneAndMarkItAsEdit = (
  lienList: Array<DocMortgagee>,
  lienPosition: number
) => {
  const lienToBeShifted =
    lienList.find((lien) => lien.lienPosition === lienPosition + 1) ||
    ({} as DocMortgagee);

  return lienList
    .map((lien) => {
      return lien.lienPosition === lienPosition
        ? {
            ...lienToBeShifted,
            ...{ lienPosition, requestType: 'edit' },
          }
        : lien;
    })
    .filter((l) => l.lienPosition !== lienPosition + 1);
};

const deleteRecentlyAddedLien = (
  lienList: Array<DocMortgagee>,
  lienPosition: number
) => {
  return lienList.filter((l) => l.lienPosition !== lienPosition);
};

const repositionLiensInAscendingOrder = (
  liens: Array<DocMortgagee>
): Array<DocMortgagee> => {
  return liens.map((lien: DocMortgagee, index) => {
    return { ...lien, ...{ lienPosition: index + 1 } };
  });
};

const getInsuredFullName = (
  policyResponse: PolicyResponseDetails | undefined,
  { insuredLastName }: InsuredContextState
) => {
  if (!policyResponse?.policySummary?.insureds) {
    return insuredLastName;
  }
  const insured = policyResponse?.policySummary.insureds.find(
    (insured) => insured.lastName === insuredLastName
  );
  return insured
    ? `${insured.firstName} ${insured.lastName}`
    : `${policyResponse?.policySummary?.insureds[0]?.firstName} ${policyResponse?.policySummary?.insureds[0]?.lastName}`;
};

const getLenderName = ({ lenderOther, lenderName }: InsuredContextState) => {
  return lenderOther || lenderName || '';
};

const getFormattedDate = ({ insuredDateOfBirth }: InsuredContextState) => {
  if (!insuredDateOfBirth) return '';
  return dayjs(insuredDateOfBirth).format('YYYY-MM-DD');
};

const buildDocRequest = (
  policyResponse: PolicyResponseDetails | undefined,
  insuredContext: InsuredContextState,
  email: boolean,
  fax: boolean,
  documentTypes: Array<string>,
  liens: Array<DocMortgagee>,
  isGuestUser: boolean
) => {
  const docsRequest: DocumentsRequest = {
    borrowerName: getInsuredFullName(policyResponse, insuredContext),
    lenderName: getLenderName(insuredContext),
    documentRequest: {
      deliveryMethod: {
        email: email ? insuredContext.lenderEmail : undefined,
        phone: fax ? '' : undefined,
      },
      documentTypes,
    },
  };
  if (liens.length > 0 || isGuestUser) {
    docsRequest.mortgageeChanges = liens;
  }
  return docsRequest;
};

const buildPolicyRequest = (insuredContext: InsuredContextState) => {
  return {
    propertyZipCode: insuredContext.propertyZipCode || '',
    insuredLastName: insuredContext.insuredLastName || '',
    insuredDOB: policyDetails.getFormattedDate(insuredContext),
    lenderName: policyDetails.getLenderName(insuredContext),
    requestorEmail: insuredContext.lenderEmail || '',
  };
};

const getNameInsured = (insureds: Array<Insured> | undefined) => {
  if (!insureds) return '';
  return insureds
    .map((ins) => ` ${ins.firstName} ${ins.lastName}`)
    ?.join()
    .trim();
};

const policyDetails = {
  getLienNumberText,
  addNewLien,
  markExistingLienToBeDeleted,
  markExistingLienToBeEdited,
  mapToLien,
  replaceExistingLienWithNewlyAddedOneAndMarkItAsEdit,
  deleteRecentlyAddedLien,
  repositionLiensInAscendingOrder,
  getInsuredFullName,
  getLenderName,
  getFormattedDate,
  buildDocRequest,
  buildPolicyRequest,
  getNameInsured,
};

export default policyDetails;
