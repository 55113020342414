import wretch, { Wretch } from 'wretch';
import { getCookie } from '../components/helpers/cookies';
import { DocumentsRequest } from './schema/apiDocuments';
import { RecaptchaValidationRequest } from './schema/apiRecaptcha';
import { AuthenticateRequest } from './schema/apiAuthenticate';
import { OtpGenerateRequest } from './schema/apiOtpGenerate';
import { OtpValidateRequest } from './schema/apiOtpValidate';

const apiUrls = {
  authenticate: `/api-policies/v1/authenticate`,
  optGenerate: `/api-policies/v1/otp/generate`,
  otpValidate: `/api-policies/v1/otp/validate`,
  policy: `/api-policies/v2/policy`,
  mortgagees: `/api-documents/v1/documents/mortgagees`,
  requestDocuments: `/api-documents/v1/documents/request-documents`,
  validateRecaptcha: `/api-policies/v1/validate-recaptcha`,
};

export const getActionCreators = (gateway: Wretch) => ({
  authenticate: (policyNr: string, body: AuthenticateRequest) =>
    gateway
      .headers(getHeaders())
      .url(`${apiUrls.authenticate}/${policyNr}`)
      .post(body)
      .json(),
  optGenerate: (policyNr: string, body: OtpGenerateRequest) =>
    gateway
      .headers(getHeaders())
      .url(`${apiUrls.optGenerate}/${policyNr}`)
      .post(body)
      .json(),
  otpValidate: (policyNr: string, body: OtpValidateRequest) =>
    gateway
      .headers(getHeaders())
      .url(`${apiUrls.otpValidate}/${policyNr}`)
      .post(body)
      .json(),
  policy: () =>
    gateway.headers(getHeaders()).url(`${apiUrls.policy}`).get().json(),
  updateLiens: (policyNr: string, body: DocumentsRequest) =>
    gateway
      .headers(getHeaders())
      .url(`${apiUrls.mortgagees}/${policyNr}`)
      .post(body)
      .json(),
  requestDocuments: (policyNr: string, body: DocumentsRequest) =>
    gateway
      .headers(getHeaders())
      .url(`${apiUrls.requestDocuments}/${policyNr}`)
      .post(body)
      .json(),
  validateRecaptcha: (body: RecaptchaValidationRequest) =>
    gateway
      .headers(getHeaders())
      .url(`${apiUrls.validateRecaptcha}`)
      .post(body)
      .json(),
});

const getHeaders = () => {
  const token = getCookie('token');
  return {
    'X-Api-Key': process.env.REACT_APP_API_KEY || '',
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
  };
};

export const useActionCreators = () => {
  return { actionCreators: getActionCreators(wretch()) };
};
