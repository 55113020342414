import React from 'react';
import InsuredContextProvider from './components/contexts/InsuredContext';
import LoadingContextProvider from './components/contexts/LoadingContext';
import IdleTimer from './components/IdleTimer';
import { Outlet } from 'react-router-dom';

const Root = () => {
  return (
    <LoadingContextProvider>
      <InsuredContextProvider>
        <React.StrictMode>
          <Outlet />
        </React.StrictMode>
        <IdleTimer />
      </InsuredContextProvider>
    </LoadingContextProvider>
  );
};

export default Root;
