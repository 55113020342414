export interface Mortgagee {
  requestType?: string;
  lienPosition?: number;
  legalMortgageeName: string;
  mortgageeAddress1: string;
  mortgageeAddress2?: string;
  mortgageeCity: string;
  mortgageeState: string;
  mortgageePostalCode: string;
  loanNumber: string;
}
export const defaultLien = {
  legalMortgageeName: '',
  mortgageeAddress1: '',
  mortgageeCity: '',
  mortgageeState: '',
  mortgageePostalCode: '',
  loanNumber: '',
};

export interface DocumentsRequest {
  lenderName?: string;
  requestorName?: string;
  phoneNumber?: string;
  borrowerName?: string;
  mortgageeChanges?: Array<Mortgagee>;
  documentRequest: {
    documentTypes: Array<string>;
    deliveryMethod: {
      email?: string;
      phone?: string;
    };
  };
}
