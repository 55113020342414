import React, { useContext, useEffect, useState } from 'react';
import '../styles/ReviewRequestCard.scss';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useActionCreators } from '../api/actions';
import useRequest from '../api/makeRequest';
import { LoadingContext } from './contexts/LoadingContext';
import { useNavigate } from 'react-router-dom';
import { InsuredContext } from './contexts/InsuredContext';
import { DocumentsRequest } from '../api/schema/apiDocuments';
import useSegment from '../hooks/useSegment';
import '@aaa-ncnu-ie/fonts/cabin/index.css';
import { getCookie } from './helpers/cookies';

function ReviewRequest(this: any) {
  const [contactInfo, setContactInfo] = useState(
    {} as {
      name: string;
      lenderName: string;
      phone: string;
      email: string;
      fax: string;
    }
  );
  const [documentDetails, setDocumentDetails] = useState(
    {} as DocumentsRequest
  );
  const [insuredContext] = useContext(InsuredContext);
  const [, updateLoadingContext] = useContext(LoadingContext);
  const navigate = useNavigate();
  const navigateHome = useNavigate();
  useEffect(() => {
    if (!getCookie('token')) {
      navigateHome('/');
    }
    setContactInfo(JSON.parse(sessionStorage.getItem('ContactInfo') || '{}'));
    setDocumentDetails(JSON.parse(sessionStorage.getItem('Documents') || '{}'));
  }, []);

  const ShowEmail = () => {
    return (
      <div className="review-section">
        <div className="review-label">
          <b>Email address</b>
        </div>
        <div className="review-field csaa-qm-encrypt">{contactInfo.email}</div>
      </div>
    );
  };
  const ShowFax = () => {
    return (
      <div className="review-section">
        <div className="review-label">
          <b>Fax number</b>
        </div>
        <div className="review-field csaa-qm-encrypt">{contactInfo.fax}</div>
      </div>
    );
  };

  const makeRequest = useRequest();
  const { track } = useSegment();

  const buildRequest = () => {
    const requestBody = {
      ...documentDetails,
      ...{
        requestorName: contactInfo.name,
        phoneNumber: contactInfo.phone,
        lenderName: contactInfo.lenderName,
      },
    };
    if (contactInfo.email) {
      requestBody.documentRequest.deliveryMethod.email = contactInfo.email;
    }
    if (contactInfo.fax) {
      requestBody.documentRequest.deliveryMethod.phone = contactInfo.fax;
    }
    return requestBody;
  };

  const {
    actionCreators: { requestDocuments, updateLiens },
  } = useActionCreators();

  const storeContactInfoAndNavigate = (isUpdateMortgagee: boolean = false) => {
    const currentContactInfo = JSON.parse(
      sessionStorage.getItem('ContactInfo') || '{}'
    );
    sessionStorage.setItem(
      'ContactInfo',
      JSON.stringify({
        ...currentContactInfo,
        isUpdateMortgagee,
      })
    );
    updateLoadingContext({ value: false });
    navigate('/success');
  };

  const requestDocs = (request: DocumentsRequest) => {
    makeRequest(() =>
      requestDocuments(insuredContext.policyNumber || '', request)
        .then(() => {
          storeContactInfoAndNavigate();
        })
        .catch(() => {
          storeContactInfoAndNavigate();
        })
    );
  };

  const updateMortgagee = (request: DocumentsRequest) => {
    makeRequest(() =>
      updateLiens(insuredContext.policyNumber || '', request)
        .then((response: any) => {
          storeContactInfoAndNavigate(true);
          track('Mortgagee Change Succeeded', {
            event_type: 'Automated System Process',
            document: request?.documentRequest?.documentTypes?.join(),
            ticket_number: response?.ticketId,
            delivery_method: Object.keys(
              request?.documentRequest?.deliveryMethod
            ).join(),
          });
          track('Exception Ticket Created', {
            ticket_source: 'Zendesk',
            ticket_number: response?.ticketId,
            event_type: 'Automated System Process',
          });
        })
        .catch(() => {
          storeContactInfoAndNavigate(true);
        })
    );
  };

  const handleClick = () => {
    const request = buildRequest();
    updateLoadingContext({
      value: true,
      message: 'We’re gathering details of the policy you’ve requested.',
    });
    track('Document Request Submitted', {
      event_type: 'User Information Entered',
      agent_name: request.requestorName,
      lender_name: request.lenderName,
      phone_number: request.phoneNumber,
      fax_number: request?.documentRequest?.deliveryMethod?.phone,
      email: request?.documentRequest?.deliveryMethod?.email,
    });
    if (request?.mortgageeChanges) {
      updateMortgagee(request);
    } else {
      requestDocs(request);
    }
  };

  return (
    <div className="DocumentDetailsCard">
      <div className="PolicyDetailsCard-title">Review your information</div>
      <div className="PolicyDetailsCard-main">
        <Container className="PolicyDetailsCard-details">
          <Box className="PolicyDetailsCard-box-details">
            <div className="PolicyDetailsCard-details-section">
              <div className="flexForm">
                <div className="title-middle">Contact information</div>
                <div className="review-results">
                  <div className="review-section">
                    <div className="review-label">
                      <b>Name</b>
                    </div>
                    <div className="review-field">{contactInfo.name}</div>
                  </div>
                  <div className="review-section">
                    <div className="review-label">
                      <b>Lender name</b>
                    </div>
                    <div className="review-field">{contactInfo.lenderName}</div>
                  </div>
                  <div className="review-section">
                    <div className="review-label">
                      <b>Phone number</b>
                    </div>
                    <div className="review-field csaa-qm-encrypt">
                      {contactInfo.phone}
                    </div>
                  </div>
                  {contactInfo.email ? <ShowEmail /> : null}
                  {contactInfo.fax ? <ShowFax /> : null}
                </div>
              </div>
            </div>
            <div className="formDirections">
              Select finish below to process your request. Your documents will
              be delivered to the methods selected shortly.
            </div>
            <div className="buttonGroup">
              <Button variant="contained" onClick={handleClick}>
                Submit request
              </Button>
              <Button variant="outlined" href="/documents">
                Edit Information
              </Button>
            </div>
          </Box>
        </Container>
      </div>
    </div>
  );
}
export default ReviewRequest;
